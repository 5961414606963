import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import { getRequest } from "../apiAdminUser";
import Logo from "./Logo";

function pluralize(count, singular, plural) {
  return count <= 1 ? singular : plural;
}

export default function EventSummary() {
  const { eventId } = useParams();
  const [summary, setSummary] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await getRequest(`getEventById/${eventId}`);
        setSummary(response.data);
        
      } catch (err) {
        console.error("Error fetching summary:", err);
        setError("An error occurred while fetching summary.");
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [eventId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='summary-container'>
        <Logo />
        <h3>Assalatur Rahman Islamic Association</h3>
        <h1>
            We are currently <strong>{summary.numberOfAttendees || 0}</strong>{" "}
            {pluralize(summary.numberOfAttendees, 'participant', 'participants')} in this event
        </h1>
        <h3>
            There are currently {summary.maleCount || 0} {pluralize(summary.maleCount, 'male', 'males')} and{" "}
            {summary.femaleCount || 0} {pluralize(summary.femaleCount, 'female', 'females')} in attendance.
        </h3>
    </div>
);
}
